@import-normalize;

@font-face {
  font-family: OpenDyslexic;
  src: url("./styles/fonts//OpenDyslexic-Regular.otf") format("opentype");
}

@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

body {
  padding: 60px 0 0 0;
  margin: 0;
}
