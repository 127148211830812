.Header {
  display: flex;
  flex-flow: row wrap;

  h2 {
    flex: 1 100%;
  }

  &__subtitle {
    margin: 0.2rem 0;
  }

  &__notes {
    width: 25rem;
    margin-right: 1rem;
  }

  &__tags {
    display: inline-flex;
    flex-direction: row;
  }

  &__tag {
    margin: 0 0.2rem 0.3rem 0;
  }
}

@media (max-width: 950px) {
  .Header {
    &__notes {
      width: auto;
      flex: 1 0 100%;
    }
  }
}
